@tailwind base;
@tailwind components;
@tailwind utilities;
body{
   overflow-x: hidden;
}
.bg{
  background-image: url('./Assets/banner.jpg');

}
.bg3{
  background-image:url('./Assets/banner.jpg');

}
.bg1{
opacity:1;
  background-image: url('./Assets/Vector-BG-4.png');
}
.bg2{
  background-image: url('./Assets/Appointment.jpg');
  background-attachment: fixed;
}
.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  position: relative;
  overflow: hidden;
}
.h{
  /* font-family: "Heebo", sans-serif */
  font-family: "Cinzel", serif;
}
.s{
  font-family: "Raleway", sans-serif;
  /* font-family: "Heebo", sans-serif */
}
.progress-bar .progress {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #4caf50;
  animation: progress-animation 2s ease-in-out;
}

@keyframes progress-animation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.css-6y5fkg-MuiPaper-root-MuiAccordion-root {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  position: relative;
  -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-anchor: none;
  border: 0px solid rgba(0, 0, 0, 0.12);
}
.preloader-area {
  z-index: 9999;
  background-color: #ffffff;
}
.text-center {
  text-align: center !important;
}
/* .preloader-area .loader {
  transform: translateY(-50%);
  top: 50%;
} */

.end-0 {
  right: 0 !important;
}
.start-0 {
  left: 0 !important;
}
.position-absolute {
  position: absolute !important;
}
/* .preloader-area .loader {
  transform: translateY(-50%);
  top: 50%;
} */
*, *::before, *::after {
  box-sizing: border-box;
}
.preloader-area .waviy {
  /* font-size: 50px; */
  margin-top: 10px;
  -webkit-box-reflect: below -35px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}
img {
  max-width: 100%;
  height: auto;
}
img, svg {
  vertical-align: middle;
}
.preloader-area .waviy {
  /* font-size: 50px; */
  margin-top: 10px;
  -webkit-box-reflect: below -35px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}
.position-relative {
  position: relative !important;
  box-sizing: border-box;
}
.d-inline-block {
  display: inline-block !important;
  box-sizing: border-box;
}
@keyframes waviy  {
  
  0%, 40%, 100% {
    animation-delay: 1s;
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}

.preloader-area .waviy {
  /* animation-delay: 0.1s; */
  animation: waviy 1s infinite;
  animation-delay: 3s;
  font-weight: 800;
  box-sizing: border-box;
}
.preloader-area .waviy span {
  animation-delay: 0.1s;
  animation: waviy 1s infinite;
  font-weight: 800;

  &:nth-child(2) {
      animation-delay: 0.2s;
  }
  &:nth-child(3) {
      animation-delay: 0.3s;
  }
  &:nth-child(4) {
      animation-delay: 0.4s;
  }
}